<template>
  <v-off-canvas id="case-create-offcanvas" :backdrop-static="false"
    :offcanvas-class="['offcanvas-end', { 'double-offcanvas': isDoubleOffCanvas }]" @closed="$emit('closed')">
    <resizable-container>
      <off-canvas-header>
        <h5 class="offcanvas-title">{{ $t('clientTour.createNewCase') }}</h5>
        <template #other-button>
          <v-button v-if="othersType || additionalInfo" :disabled="isEmptyParticipant(participantsDetails) && agency?.isCollaborator"
            :is-loading="accepted" class="btn-icon w-fit align-self-end" size="sm" variant="primary"
            @click="openCaseDetailsModal()">
            {{ $t("general.button.getAQuote") }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
          <v-button v-else
            :disabled="(isEmptyParticipant(participantsDetails) && agency?.isCollaborator) || isEmptyRooming(rooming) || disableButton"
            :is-loading="accepted" class="btn-icon w-fit align-self-end" size="sm" variant="primary"
            @click="openCaseDetailsModal()">
            {{ $t('general.button.book') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </template>
      </off-canvas-header>
      <off-canvas-body>
        <form id="case-create-form" class="d-flex flex-column justify-content-between h-100">
          <div class="search-separator-container">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t('clientTour.howWillYourPath') }}</span>
            </div>
          </div>

          <fieldset>

            <div class="form-outline mb-4">
              <label class="label">
                <span>{{ $t('clientTour.products') }}*</span>
              </label>

              <v-select id="case-create-path-select" v-model="template" :options="templates" class="select-search mb-8"
                @search="onSearch">
                <template v-slot:no-options>
                  {{ $t('clientTour.templateNoOptions') }}
                </template>

                <template v-slot:option="option">
                  <div class="d-center">
                    <span>{{ option?.name }}</span>
                  </div>
                </template>

                <template v-slot:selected-option="option">
                  <div class="selected d-center py-8">
                    <span>{{ option?.name }}</span>
                  </div>
                </template>
              </v-select>
            </div>
          </fieldset>
          <div style="margin-bottom: 24px" v-if="template?.id">
            <div class="d-flex">
              <div class="me-4 slide-data-wrap">
                Travel Type &nbsp;: &nbsp;
                <strong>
                  {{ template.travelType }}
                </strong>
              </div>
              <div class="ms-4 slide-data-wrap">
                No. of Days &nbsp;: &nbsp;
                <strong>
                  {{ template.tourDaysCount }}
                </strong>
              </div>
            </div>
          </div>
          <div>
            <fieldset>
              <legend class="visually-hidden">{{ $t('clientTour.basicInformation') }}</legend>
              <div class="search-separator-container">
                <div class="separator-text-container">
                  <span class="separator-text text-sm">{{ $t('clientTour.basicInformation') }}</span>
                </div>
              </div>
              <v-select-client-search v-if="type !== 'template'" id="case-create-client-search" v-model="clientId"
                :agency="agency.slug" :is-disabled="!template?.id" />

              <v-form-input id="case-create-pax" v-model="pax" :label="$t('general.shared.pax')"
                :disabled="!template?.id" :yup-errors-variable="errors.pax" autocomplete="off" form-type="outline"
                type="number" @input="handlePaxInput()" @wheel.prevent />
              <div id="case-create-init-date" class="form-outline">
                <label class="label" :class="{ disabled: !template?.id }">
                  <span>{{ $t('general.shared.startDate') }}*</span>
                </label>
                <Datepicker v-model="initDate" :enable-time-picker="false" :disabled="!template?.id"
                  :format="settings.formatDate" :highlight="highlightedDates"
                  :input-class-name="`form-control ${!!errors.initDate ? 'is-invalid' : ''}`" :min-date="minDate"
                  :month-change-on-scroll="false" auto-apply calendar-cell-class-name="init-date-cell"
                  class="form-datepicker" />
                <span v-if="!!errors.initDate" class="invalid-feedback d-block">
                  {{ errors.initDate }}
                </span>
              </div>

              <div id="case-create-init-date" class="form-outline mb-16">
                <label class="label" :class="{ disabled: !template?.id }">
                  <span>{{ $t('general.shared.alternativeStartDate') }}</span>
                </label>
                <Datepicker v-model="alternativeStartDate" :enable-time-picker="false" :format="settings.formatDate"
                  :highlight="highlightedDates" :disabled="!template?.id"
                  :input-class-name="`form-control ${!!errors.alternativeStartDate ? 'is-invalid' : ''}`"
                  :min-date="incrementDateByOneDay(initDate)" :month-change-on-scroll="false" auto-apply
                  calendar-cell-class-name="init-date-cell" class="form-datepicker" />
              </div>
              <div class="search-separator-container" v-if="agency?.isCollaborator">
                <div class="form-outline flex-container">
                  <span class="separator-text text-sm" :class="{ 'span-disabled': !template?.id }">{{
                    $t('clientTour.participantsDetails') }}*</span>
                  <div class="flex-grow"></div>
                  <v-button class="btn-icon p-1
                  rounded-circle" @click.stop size="sm" variant="gradient" @click="toggleCheckboxes">
                    <template v-if="showCheckboxes">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
                        <defs></defs>
                        <g class="icon-svg"
                          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                          <path
                            d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                            class="icon-svg-minus" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path
                            d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                            class="icon-svg-minus" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                      </svg>
                    </template>
                    <template v-else>
                      <v-icon icon="plus" />
                    </template>
                  </v-button>
                </div>
                <template v-if="showCheckboxes">
                  <div :class="{ 'scrollable-padding': participantsDetails.length > 2 }" class="scrollable-container"
                    :style="{ maxHeight: participantsDetails.length > 2 ? '200px' : 'auto', overflowY: participantsDetails.length > 2 ? 'scroll' : 'visible' }">
                    <div v-for="(participant, index) in participantsDetails" :key="index" class="participants-wrap">
                      <v-form-input id="name-input" v-model="participant.name" class="px-0 name-input" :type="'text'"
                        autocomplete="off" :yup-errors-variable="errors.name" form-type="outline"
                        :disabled="!template?.id" @input="handleParticipantNameInput($event, index)"
                        :label="$t('general.shared.name')" style="margin-right: 9px;" />
                      <span v-if="errors?.participants?.[index]?.name">
                        {{ errors.participants[index].name[0] }} </span>
                      <v-form-input v-model="participant.age" :yup-errors-variable="errors.age" type="number"
                        :disabled="!template?.id" autocomplete="off" :label="$t('general.shared.age')"
                        form-type="outline" id="age-input" class="ml-12 age-input"
                        @input="handleParticipantAgeInput($event, index)" @wheel.prevent />
                      <span v-if="errors?.participants?.[index]?.age">
                        {{ errors.participants[index]?.age[0] }} </span>
                    </div>
                  </div>
                </template>
              </div>
              <div class="search-separator-container">
                <div class="form-outline flex-container">
                  <span class="separator-text text-sm" :class="{ 'span-disabled': !template?.id }">{{
                    $t('clientTour.rooming')
                    }}*</span>
                  <div class="flex-grow"></div>
                  <v-button class="btn-icon p-1 rounded-circle" @click.stop size="sm" variant="gradient"
                    @click="toggleRooming">
                    <template v-if="isCreateRoom">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
                        <defs></defs>
                        <g class="icon-svg"
                          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                          <path
                            d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                            class="" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path
                            d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                            class="icon-svg-minus" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                      </svg>
                    </template>
                    <template v-else>
                      <v-icon icon="plus" />
                    </template>
                  </v-button>
                </div>
                <template v-if="isCreateRoom">
                  <div class="scrollable-rooming"
                    :style="{ maxHeight: rooming.length > 2 ? '300px' : 'auto', overflowY: rooming.length > 2 ? 'scroll' : 'visible' }"
                    :class="{ 'scrollable-padding': rooming.length > 2 }">
                    <div class="heading">
                    </div>
                    <div v-for="(room, index) in rooming" :key="index">
                      <div class="Participants-wrapper position-relative d-md-flex">
                        <div class="form-outline w-100">
                          <div class="open-question-icon" v-if="othersType">
                            <div class="others-type-tooltip-description"
                              :data-title="$t('general.toast.otherTypeTooltipMessage')">
                              <v-icon icon="question" size="12" style="opacity: 0.5;" />
                              <div class="tooltip-right-arrow"></div>
                            </div>
                          </div>
                          <label class="label" :class="{ disabled: !template?.id }">
                            <span v-if="!othersType || room.type?.value === 'other_room'">Room Type</span>
                          </label>
                          <v-select v-if="!othersType || room.type?.value === 'other_room'" form-type="outline"
                            :clearable="false" :disabled="!template?.id"
                            :options="roomTypes.map(roomType => ({ value: roomType.value, label: $t(roomType.label) }))"
                            style="min-width:235px; padding:0px 0px;" v-model="room.type"
                            @input="(newValue: number) => handleRoomingPaxInput(room, newValue)"
                            @change="handleRoomChange(room)">
                            <template v-slot:no-options>
                              <div class="my-4">{{ $t('general.vueSelect.noOptions') }}</div>
                            </template>
                          </v-select>
                        </div>
                        <div v-if="othersType == false" class="form-outline mx-2">
                          <label class="label" :class="{ disabled: !template?.id }">
                            <span>Quantity</span>
                          </label>
                          <v-form-input class="quantity" type="number" form-type="outline" autocomplete="off"
                            :placeholder="$t('general.shared.quantityShort')" id="qty-input" :disabled="!template?.id"
                            style="min-width :110px; max-width: 120px;" v-model="room.quantity"
                            @input="(newValue: number) => { handleRoomingPaxInput(room, newValue); handleRoomChange(room) }"
                            @change="handleRoomChange(room)"
                            @keydown="(event: any) => handleKeydown(event, room, 'quantity')" @wheel.prevent />

                        </div>
                        <div v-if="othersType == false" class="form-outline">
                          <label class="label" :class="{ disabled: !template?.id }">
                            <span>{{ $t('general.shared.pax') }}</span>
                          </label>
                          <!-- Pax Field -->
                          <v-form-input type="number" autocomplete="off" form-type="outline"
                            :placeholder="$t('general.shared.pax')" id="roomingPaxInput" :disabled="!template?.id"
                            style="min-width:80px; max-width: 80px" class="ml-4 mr-4 pax" v-model="room.pax"
                            @input="(newValue: number) => handleRoomingPaxInput(room, newValue)" @wheel.prevent />
                        </div>
                        <div class="remove-trash-icon" v-if="!othersType || room.type.value === 'other_room'"
                          v-show="rooming.length > 1">
                          <v-icon @click="removeRoomingEntry(index)" icon="trash" size="12" class="removeRoomEntry" />
                        </div>
                      </div>
                      <div class="error-message" v-if="room.error && !othersType">{{ room.error }}</div>
                    </div>

                    <v-button v-if="othersType == false || rooming.length === 0"
                      class="btn-icon p-1 rounded-circle add-btn" variant="gradient" style="margin-left: 10px;"
                      size="sm" @click="addRoomingEntry">
                      <template v-slot:default>
                        <v-icon icon="plus" />
                      </template>
                    </v-button>
                    <div class="clear"></div>
                    <div class="flex-row total-wrapper total-section" v-if="othersType == false">
                      <label>Total:</label>
                      <v-form-input id="totalRoomingPax" :type="'text'" style="width: 25%; margin-left: 8px;"
                        v-model="totalRoomingPax" class="custom" :disabled="true" />
                    </div>
                    <div v-if="showError && othersType == false" class="error-message">
                      Total rooming capacity does not match the no of participants.
                    </div>
                  </div>
                  <div v-if="othersType == true">
                    <v-html-editor v-model="other_room_requirements" id="collaborator-other-room-requirements"
                      ref="collaborator-other-room-requirements" />
                  </div>
                </template>
              </div>
            </fieldset>
            <div>
              <div>
                <div class="form-outline flex-container">
                  <span class="separator-text text-sm" :class="{ 'span-disabled': !template?.id }">Additional
                    Information</span>
                  <div class="flex-grow"></div>
                  <v-button class="btn-icon p-1 rounded-circle" @click.stop size="sm" variant="gradient"
                    @click.prevent="toggleAdditionalInfo">
                    <template v-if="showAdditionalInfo">
                      <!-- eslint-disable-next-line max-len -->
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
                        <defs></defs>
                        <g class="icon-svg"
                          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                          <path
                            d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                            class="icon-svg-minus" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path
                            d="M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z"
                            class="icon-svg-minus" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                      </svg>
                    </template>
                    <template v-else>
                      <v-icon icon="plus" />
                    </template>
                  </v-button>
                </div>
                <div class="additionalInfo" v-if="showAdditionalInfo">
                  <v-html-editor id="case-tour-additional-infor" ref="case-tour-additional-infor-ref"
                    :disabled="!template?.id" v-model="additionalInformation" />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex transfer-btn-wrap">
            <div :class="['btn-icon', 'mt-20', 'action-btn', 'me-5', { 'checked-class': transferCheck }]">
              <v-icon class="icon-transfer text-xxl text-black icon" />
              Transfer
              <input type="checkbox" class="custom-checkbox" v-model="transferCheck" :disabled="!template?.id" />
            </div>
            <div :class="['btn-icon', 'mt-20', 'action-btn', { 'checked-class': transportCheck }]">
              <v-icon class="icon-luggage-cart text-xxl text-black icon" />
              Transport
              <input type="checkbox" class="custom-checkbox" v-model="transportCheck" :disabled="!template?.id" />
            </div>
          </div>
          <v-button v-if="othersType || additionalInfo" :disabled="(isEmptyParticipant(participantsDetails) && agency?.isCollaborator)"
            :is-loading="accepted" class="btn-icon w-fit align-self-end mt-24" size="sm" variant="primary"
            @click="openCaseDetailsModal()">
            {{ $t("general.button.getAQuote") }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
          <v-button v-else
            :disabled="(isEmptyParticipant(participantsDetails) && agency?.isCollaborator) || isEmptyRooming(rooming) || disableButton"
            :is-loading="accepted" class="btn-icon w-fit align-self-end mt-24" size="sm" variant="primary"
            @click="openCaseDetailsModal()">
            {{ $t('general.button.book') }}
            <v-icon icon="arrow-right" size="sm" space="ms-12" />
          </v-button>
        </form>
      </off-canvas-body>
    </resizable-container>
  </v-off-canvas>
  <v-modal v-if="isSelectedClientItem" @closed="closeClientModal">
    <modal-header class="header-container">
      <h5>{{ template.name }}</h5>
      <button type="button" @click="closeClientModal" aria-label="Close" class="btn-icon btn fw-medium p-4"
        data-bs-dismiss="modal" title="Close"><span class="icon-close icon"></span></button>
    </modal-header>
    <modal-body class="modalMessage">
      <v-table class="modalTable">
        <tr>
          <th class="modalMainHeadings">Tour Details</th>
        </tr>
        <tr>
          <td>{{ $t('general.shared.travelType') }}</td>
          <td class="tripDetailsHeader">{{ capitalizeFirstLetter(template.travelType) }} </td>
        </tr>
        <tr>
          <td>{{ $t('general.shared.startDate') }}</td>
          <td class="tripDetailsHeader">{{ initDate.toLocaleDateString('en-GB') }}</td>
        </tr>
        <tr>
          <td>{{ $t('general.shared.alternativeStartDate') }}</td>
          <td class="tripDetailsHeader">
            {{ alternativeStartDate ? alternativeStartDate.toLocaleDateString('en-GB') : ' -- ' }}</td>
        </tr>
        <tr>
          <td>{{ $t('general.shared.pax') }}</td>
          <td class="tripDetailsHeader">{{ pax }}</td>
        </tr>
        <tr>
          <td>{{ $t('general.shared.transfer') }}</td>
          <td class="tripDetailsHeader">{{ transferCheck ? $t('general.button.yes') : $t('general.button.no') }}</td>
        </tr>
        <tr>
          <td>{{ $t('general.shared.transport') }}</td>
          <td class="tripDetailsHeader">{{ transportCheck ? $t('general.button.yes') : $t('general.button.no') }}</td>
        </tr>

      </v-table>
      <br>
      <v-table class="modalTable" v-if="agency?.isCollaborator">
        <tr>
          <th class="modalMainHeadings">{{ $t('clientTour.participantsDetails') }}</th>
        </tr>
        <tr>
          <th class="headingBoarder">{{ $t('general.shared.name') }}</th>
          <th class="headingBoarder tripDetailsHeader">{{ $t('general.shared.age') }}</th>
        </tr>
        <tbody>
          <tr v-for="(participant, index) in participantsDetails" :key="index">
            <td>{{ participant.name }}</td>
            <td>{{ participant.age }}</td>
          </tr>
        </tbody>
      </v-table>
      <br>
      <v-table class="modalTable" v-if="!other_room_requirements">
        <tr>
          <th class="modalMainHeadings">Rooming Details</th>
        </tr>
        <tr>
          <th class="headingBoarder">Room Type</th>
          <th class="headingBoarder">Quantity</th>
          <th class="headingBoarder">{{ $t('general.shared.pax') }}</th>
        </tr>
        <tbody>
          <tr v-for="(room, index) in rooming" :key="index">
            <td>{{ room.type.value }}</td>
            <td class="tripDetailsHeader">{{ room.quantity }}</td>
            <td class="tripDetailsHeader">{{ room.pax }}</td>
          </tr>
        </tbody>
      </v-table>
      <div v-if="other_room_requirements">
        <div class="additionalInformation">Other Room Requirement</div>
        <div class="additionalInformationContent" v-html="other_room_requirements"></div>
      </div>
      <div v-if="additionalInformation">
        <div class="additionalInformation">Additional Requirement</div>
        <div class="additionalInformationContent" v-html="additionalInformation"></div>
      </div>
      <div>
      </div>
      <div class="d-flex justify-content-between confirmSection">
        <button type="button" @click="closeClientModal" class="btn btn-sm btn-danger" :disabled="disableButton">
          {{ $t('general.button.cancel') }}</button>
        <button type="button" class="btn btn-sm btn-primary" @click="createClientTour" :disabled="disableButton">{{
          $t('general.button.confirm') }}</button>
      </div>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { Ref, computed, defineComponent, watch } from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { VFormInput } from '@uniqoders/form';
import { useI18n } from 'vue-i18n';
import { mapState } from 'pinia';
import Datepicker from '@vuepic/vue-datepicker';
import { isEqual, set } from 'date-fns';
import { DateTime } from 'luxon';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAuthStore } from '@/stores/auth';
import api from '@/api';
import ClientTour from '@/api/objects/ClientTour';
import VSelectClientSearch from '@/components/vendor/selectSearch/VSelectClientSearch.vue';
import Template from '@/api/objects/Template';
import Location from '@/api/objects/Location';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import { useAppStore } from '@/stores/app';
import ResizableContainer from '@/components/resizablecontainer/ResizableContainer.vue';
import vSelect from 'vue-select';
import VHtmlEditor from '@/components/vendor/htmlEditor/VHtmlEditor.vue';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import { ref } from 'vue';

export default defineComponent({
  name: 'TheCaseCreateOffcanvas',
  components: {
    VSelectClientSearch,
    VIcon,
    VButton,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VFormInput,
    Datepicker,
    ResizableContainer,
    vSelect,
    VHtmlEditor,
    VModal
  },
  emits: [
    'closed',
    'reloadClientTours',
  ],
  props: {
    clientTourData: {
      type: ClientTour,
      required: false,
      default: null,
    },
    isDoubleOffCanvas: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    //const { agency } = useAuthStore();
    const error = ref<string>('');
    const participantsDetails: Ref<any[]> = ref([
      { name: '', age: null },
    ]); const receiveRooming: never[] = []
    const roomingDetails = ref([]);
    const showCheckboxes = ref(false);
    const showAdditionalInfo = ref(false);
    const totalErrorMessage = ref(false)
    const isCreateRoom = ref(false);
    const rooming: Ref<any[]> = ref([{ type: '', quantity: null, pax: null, }]);
    const additionalInformation = ref('');
    const other_room_requirements = ref('');
    const additionalInfo = ref(false);
    const othersType = ref(false);
    const transferCheck = ref(false);
    const transportCheck = ref(false);
    const isEmptyParticipant = (array: any[]) => {
      return !array.every(participant => participant.name && participant.age);
    };
    const isEmptyRooming = (array: any[]) => {
      return !array.every(room => room.type.value && room.quantity && room.pax);
    };


    const rules = yup.object({
      isTemplate: yup.bool().default(true),
      type: yup.string()
        .nullable()
        .label(t('clientTour.caseType')),
      origin: yup.lazy((value, options) => {
        const rule = yup.mixed()
          .label(t('general.shared.origin'));
        if (options.parent.isTemplate) {
          return rule.required();
        }
        return rule.nullable();
      }),
      initDate: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.initDate')),
      clientId: yup.lazy((value, options) => {
        const rule = yup.mixed()
          .nullable()
          .label(t('general.shared.client'));
        if (options.parent.type !== 'template') {
          return rule.required();
        }
        return rule.nullable();
      }),
      pax: yup.number()
        .nullable()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.pax')),
    });

    watch(
      () => rooming.value,
      (newValue) => {

        newValue.forEach(room => {
          const selectedRoomType = room.type;
          switch (selectedRoomType.value) {
            case 'other_room':
              setTimeout(() => {
                othersType.value = true;
              }, 700);
              for (let i = rooming.value.length - 1; i >= 0; i--) {
                if (rooming.value[i].type.value !== 'other_room') {
                  rooming.value.splice(i, 1);
                }
              }
              break;
            default:
              othersType.value = false;
              break;
          }

        });

      },
      { deep: true }
    );

    const toggleCheckboxes = () => {
      showCheckboxes.value = !showCheckboxes.value;
    };
    const toggleAdditionalInfo = () => {
      if (additionalInfo.value !== null) {
        setTimeout(() => {
          showAdditionalInfo.value = !showAdditionalInfo.value;
        }, 300);
      }
    };
    const toggleRooming = () => {
      isCreateRoom.value = !isCreateRoom.value;
      if (isCreateRoom.value && rooming.value.length === 0) {
        addRoomingEntry();
      }
    };
    const addRoomingEntry = () => {
      rooming.value.push({
        type: '',
        quantity: null,
        pax: null,
      });
    };
    const roomTypes = [
      {
        id: "singleRoom",
        label: "supplier.singleRoom",
        value: "single_room",
        roomName: "supplier.singleRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "doubleRoom",
        label: "supplier.doubleRoom",
        value: "double_room",
        roomName: "supplier.doubleRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "doubleTwinRoom",
        label: "supplier.doubleTwinRoom",
        value: "double_twin_room",
        roomName: "supplier.doubleTwinRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "tripleRoom",
        label: "supplier.tripleRoom",
        value: "triple_room",
        roomName: "supplier.tripleRoomFullName",
        freeText: "",
        price: "",
      },
      {
        id: "other_room",
        label: "Others",
        value: "other_room",
        roomName: "Others",
        freeText: "",
        price: "",
      },
    ];

    const totalRoomingPax = computed(() => {
      return rooming.value.reduce((total, room) => {
        return total + room.pax;
      }, 0);
    });

    const checkRoomingForOthers = computed(() => {
      return rooming.value.some(room => room.type === 'other_room');
    });

    watch(checkRoomingForOthers, (newValue) => {
      othersType.value = newValue;
    });

    const handleInput = (event: any, room: any, field: string) => {
      const newValue = parseInt(event.target.value, 10);
      if (validatePositiveNumber(newValue)) {
        room[field] = newValue;
        handleRoomingPaxInput(room, field);
      } else {
        event.target.value = null;
      }
    };

    const validatePositiveNumber = (value: number) => {
      return value > 0;
    };

    const handleKeydown = (event: any, room: any, field: string) => {
      if (event.key === 'ArrowDown' && room[field] <= 0) {
        event.preventDefault();
      }
    };
    const handleRoomingPaxInput = (room: any, newPax: number) => {
      if (room.pax < 1 || newPax.target.value < 1) {
        room.pax = '';
      }
      const roomType = room.type.label;
      const quantity = room.quantity;
      let maxPax = 0;
      switch (roomType) {
        case 'Double B&B':
          maxPax = 2 * quantity;
          othersType.value = false;
          break;
        case 'Single B&B':
          maxPax = 1 * quantity;
          othersType.value = false;
          break;
        case 'Triple B&B':
          maxPax = 3 * quantity;
          othersType.value = false;
          break;
        case 'Double Twin B&B':
          maxPax = 2 * quantity;
          othersType.value = false;
          break;
        case 'other_room':
          othersType.value = true;
          for (let i = rooming.value.length - 1; i >= 0; i--) {
            if (rooming.value[i].type.value !== 'other_room') {
              rooming.value.splice(i, 1);
            }
          }
          break;
        default:
          othersType.value = false;
          break;
      }

      if (room.pax > maxPax && maxPax !== 0) {
        room.error = `Entered pax exceeds the maximum allowed ${maxPax} for the selected room type ${roomType} and quantity ${quantity}.`;
      } else {
        room.error = null;
      }
    };

    const initialValues: Record<string, any> = {};

    const form = useFormValidation(rules, initialValues);

    const { value: type } = useField<string>('type');
    const { value: initDate } = useField<Date | any>('initDate');
    const { value: isTemplate } = useField('isTemplate');
    const { value: clientId } = useField('clientId');
    const { value: pax } = useField<number>('pax');
    const { value: origin } = useField<any>('origin');
    const { value: destination } = useField<any>('destination');
    const { value: travelType } = useField('travelType');


    const { value: alternativeStartDate } = useField<Date | any>('alternativeStartDate');

    watch(pax, (newPax) => {
      if (isValidPax(newPax)) {
        const currentLength = participantsDetails.value.length;

        if (newPax > currentLength) {
          // Add new participants with empty name, age, and initially disabled button
          const newParticipants = Array.from({ length: newPax - currentLength }, () => ({ name: '', age: null }));
          participantsDetails.value = [...participantsDetails.value, ...newParticipants];
        } else if (newPax < currentLength) {
          // Remove excess participants
          participantsDetails.value = participantsDetails.value.slice(0, newPax);
        }
      }
    });

    function isValidPax(input: number): boolean {
      return Number.isInteger(input) && input >= 0;
    }
    return {
      ...useConcepts(),
      ...form,
      isTemplate,
      type,
      initDate,
      clientId,
      pax,
      origin,
      destination,
      travelType,
      alternativeStartDate,
      participantsDetails,
      roomingDetails,
      toggleCheckboxes,
      toggleAdditionalInfo,
      showCheckboxes,
      showAdditionalInfo,
      receiveRooming,
      toggleRooming,
      isCreateRoom,
      roomTypes,
      othersType,
      addRoomingEntry,
      rooming,
      totalRoomingPax,
      handleRoomingPaxInput,
      error,
      totalErrorMessage,
      showError: false,
      isEmptyParticipant,
      isEmptyRooming,
      additionalInformation,
      other_room_requirements,
      additionalInfo,
      handleInput,
      validatePositiveNumber,
      handleKeydown,
      transferCheck,
      transportCheck
    };
  },
  data() {
    return {
      highlightedDates: [] as Date[],
      date: null as any,
      disableButton: false as boolean,
      templateSelected: false as boolean,
      isLoadingTemplates: false as boolean,
      templates: [] as Template[],
      template: {} as Template | Record<string, any>,
      templateLocations: [] as Location[],
      minDate: DateTime.now(),
      isSelectedClientItem: false
    };
  },
  computed: {
    ...mapState(useAuthStore, ['agency']),
    ...mapState(useAppStore, ['settings']),
  },
  watch: {
    totalRoomingPax(newTotalRoomingPax) {
      this.showError = newTotalRoomingPax !== this.pax;
      this.disableButton = this.showError;
    },

    additionalInformation(newVal) {
      this.additionalInfo = newVal.trim().length > 0;
    },

    pax(newPax) {
      const roomTypeAndQuantitySelected = this.rooming.some(room => room.type && room.quantity > 0);
      if (roomTypeAndQuantitySelected) {
        this.showError = this.totalRoomingPax !== newPax;
        this.disableButton = this.showError;
      } else {
        // Reset error state if room type and quantity are not selected
        this.showError = false;
        this.disableButton = false;
      }
    },
    template: {
      handler() {
        if (!!this.template && !!this.template.id) {
          this.isTemplate = true;
          this.origin = this.template.originId;
          this.destination = this.template.destinationId;
          this.loadTemplateLocations();
        } else {
          this.isTemplate = false;
          this.origin = null;
          this.destination = null;
        }
      },
      deep: true,
    },

  },
  created() {
    this.loadAgencyTemplates();
  },

  methods: {
    async onSearch(searchTerm: string) {
      await this.loadAgencyTemplates(searchTerm);
    },

    closeClientModal() {
      this.isSelectedClientItem = false;
    },
    openCaseDetailsModal() {
      this.disableButton = false;
      this.isSelectedClientItem = !this.isSelectedClientItem;
    },
    handleParticipantAgeInput(event: any, index: number) {
      let newValue = parseFloat(event.target.value);
      if (newValue < 1) {
        newValue = 1;
      } else if (newValue > 120) {
        newValue = 120;
      }
      this.participantsDetails[index].age = newValue;
    },

    handleParticipantNameInput(event: any, index: number) {
      if (event.target.value.length > 70) {
        this.participantsDetails[index].name = event.target.value.slice(0, 70);
      } else {
        this.participantsDetails[index].name = event.target.value;
      }
    },

    handlePaxInput() {
      if (this.pax < 1) {
        this.pax = null
      } else if (this.pax > 200) {
        this.pax = 200
      }
    },
    sanitizeDescription(additionalInformation: string) {
      return additionalInformation ? additionalInformation.replace(/<\/?p>/g, '') : '';
    },
    sanitizeRequirement(other_room_requirements: string) {
      return other_room_requirements ? other_room_requirements.replace(/<\/?p>/g, '') : '';
    },

    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    removeRoomingEntry(index: number) {
      this.rooming.splice(index, 1); // Remove the rooming entry at the specified index
    },
    handleRoomChange(room: { type: string; quantity: number; pax: number }) {
      const selectedRoomType = room.type;
      let pax = 0;
      const quantity = room.quantity;
      switch (selectedRoomType.label) {
        case 'Single B&B':
          pax = 1 * quantity;
          break;
        case 'Double B&B':
          pax = 2 * quantity;
          break;
        case 'Triple B&B':
          pax = 3 * quantity;
          break;
        case 'Double Twin B&B':
          pax = 2 * quantity;
          break;
        case 'Others':
          break;
        default:
          pax = 0;
          break;
      }
      this.updateRoomPax(room, pax);
    },

    updateRoomPax(room: { type?: string; quantity?: number; pax: any; }, newPax: number) {
      room.pax = newPax;
      if (!room.quantity) {
        room.pax = '';
      }
    },

    incrementDateByOneDay(date) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    },

    async loadAgencyTemplates(searchKeyword?: string) {
      if (!!this.agency) {
        const filters: any = {
          filters: {
            status: ["active"],
          },
        };

        if (searchKeyword) {
          filters.filters.name = searchKeyword;
        }
        const templates = await api.template.all(this.agency.slug, filters);
        this.templates = templates.map((template: any) => ({
          ...template,
          label: template.name,
        }));
      }
    },
    async loadTemplateLocations() {
      if (!!this.agency) {
        this.templateLocations = await api.template.locations(this.agency.slug, this.template.id);
      }
    },
    async createClientTour() {
      try {
        this.disableButton = true;
        this.toggleAccepted();
        const data: any = {
          client_id: this.clientId,
          init_date: this.initDate,
          pax_number: Number(this.pax),
          type: this.type,
          travel_type: this.travelType,
          alternate_start_date: this.alternativeStartDate,
          participantsDetails: this.participantsDetails,
          rooming: this.cleanRoomingData(this.rooming),
          additional_requirements: this.additionalInformation,
          other_room_requirements: this.other_room_requirements,
          transport: this.transportCheck,
          transfer: this.transferCheck
        };

        if (this.othersType) {
          delete data.rooming;
        }

        if (!!this.template) {
          data.template_id = this.template.id;
        }

        // TODO: Si el tour es 'Single' el tour lo crea API. Cuando sea 'Group' se tendrá que crear el tour a parte
        if (!!this.agency) {
          await api.case.createCollaborator(this.agency.slug, data);
          this.$toast.success('Case created successfully for activity ' + this.template.name + ' from ' + this.template.origin?.name + ' to ' + this.template.destination?.name);
          this.resetForm();
          this.$emit('reloadClientTours');
          this.$emit('closed')
        }
      } catch (e: any) {
        this.disableButton = false;
        this.toggleAccepted();
        console.error(e);
        this.$toast.error(e.response.data.message);
      }
    },
    cleanRoomingData(rooming) {
      return rooming.map(room => ({
        type: room.type.value,
        quantity: room.quantity,
        pax: room.pax
      }));
    },
    async createLocation(address: Record<string, any>, isOrigin = true) {
      const location: Location = await api.location.create({
        name: address.name,
        provider_id: address.placeId,
        lat: address.lat,
        lng: address.lng,
        country: address.country.longName,
        province: address.regionProvince.longName,
        state: address.stateCommunity.longName,
      });

      if (isOrigin) {
        this.origin = location.id;
      } else {
        this.destination = location.id;
      }
    },
    getInitDayClass(date: Date) {
      if (isEqual(date, set(this.initDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }))) return 'init-marked-cell';
      return '';
    },
  },
});
</script>
<style scoped>
.remove-trash-icon {
  position: absolute;
  right: 0px;
  top: -8px;
  z-index: 6;
}

.total-section {
  margin-top: 16px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Participants-wrapper {
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
}

.mx-2 {
  margin: 0 10px;
}

/* Style for the close button */
.close-button {
  background: none;
  border: none;
  cursor: pointer;

}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  display: flex;
  align-items: center;
}

.form-control {
  border: none;
}

.rounded-circle {
  height: 1.1rem;
  width: 1.1rem;
}

.icon-plus {
  font-size: 0.6rem;
  margin-top: 1.5px;
}

.error-message {
  color: red;
  display: block;
  font-size: 12px;
  white-space: normal;
}

.offcanvas .offcanvas-body {
  margin-right: 0px !important;
}

.icon-svg {
  stroke: none;
  stroke-width: 0;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: none;
  fill-rule: nonzero;
  opacity: 1;
}

.icon-svg-minus {
  stroke: none;
  stroke-width: 1;
  stroke-dasharray: none;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 10;
  fill: rgb(29, 29, 27);
  fill-rule: nonzero;
  opacity: 1;
}

.removeRoomEntry {

  color: red;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 20px;
  font-size: 12px;
  align-items: center
}

.modalMessage {
  padding-left: 15px;
  padding-right: 15px;

}

.modalTable {
  width: 100%;
  margin-bottom: 10px;
}

.modalTable tr {
  border: 1px solid #ddd;
  padding: 15px;
}

.modalTable td {
  border: 1px solid #ddd;
  padding: 15px;
  width: 100%;
}

.tripDetailsHeader {
  text-align: center;
}

.modalTable th {
  padding: 5px;
}

.confirmSection {
  margin-top: 30px
}

.headingBoarder {
  border: 1px solid #ddd;
  padding: 15px !important
}

.modalMainHeadings {
  padding-top: 20px ! important;
  padding-bottom: 20px ! important;
}

.scrollable-container {
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.scrollable-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #afa6a6;

}

.scrollable-container::-webkit-scrollbar {
  width: 10px;
  background-color: #afa6a6;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #afa6a6;
  border: 2px solid #afa6a6;
}

.scrollable-rooming {
  white-space: nowrap;
  box-sizing: border-box;
}

.scrollable-rooming::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #afa6a6;

}

.scrollable-rooming::-webkit-scrollbar {
  width: 10px;
  background-color: #afa6a6;
}

.scrollable-rooming::-webkit-scrollbar-thumb {
  background-color: #afa6a6;
  border: 2px solid #afa6a6;
}

.additionalInfo {
  margin-top: 20px;
}

.additionalInformation {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px;
}

.additionalInformationContent {
  margin-left: 10px;
}

.clear {
  clear: both;
}

.Participants-wrapper {
  padding: 10px 0px;
}

.participants-wrap {
  display: flex;
}

.scrollable-padding {
  padding: 0 10px 0 0;
  overflow-x: hidden;
}

.name-input {
  width: 70%;
}

.age-input {
  width: 30%;
}

.slide-data-wrap {
  border: 1px solid #0000001a;
  padding: 7px;
  border-radius: 10px;
  font-size: 12px;
  background: #fff;
  box-shadow: 2px 2px 5px -1px #0000002e;
  display: flex;
  align-items: center;
}

.slide-data-wrap strong {
  font-size: 16px;
  text-transform: capitalize
}

.open-question-icon {
  position: absolute;
  right: 1px;
  top: -12px;
  z-index: 11;
}

.others-type-tooltip-description {
  cursor: pointer;
  margin-left: 5px;
  margin-top: 20px;
  font-size: 17px;
  align-items: center;
  position: relative;
}

.others-type-tooltip-description[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.others-type-tooltip-description[data-title]:after {
  content: attr(data-title);
  background-color: #808080;
  ;
  color: #fff;
  font-size: 13px;
  position: absolute;
  padding: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #808080;
  opacity: 0;
  border: 1px solid #808080;
  z-index: 99999;
  visibility: hidden;
  border-radius: 5px;
  top: -6px;
  right: 28px;
  height: 30px;
  width: fit-content;
  line-height: 20px;
  font-family: 'Poppins', sans-serif;
}

.others-type-tooltip-description[data-title] {
  position: relative;
}

.tooltip-right-arrow {
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  width: 10px;
  height: 10px;
  background: #808080;
  position: absolute;
  right: 18px;
  top: 5px;
  visibility: hidden;
  opacity: 0;
}

.others-type-tooltip-description[data-title]:hover .tooltip-right-arrow {
  visibility: visible;
  opacity: 1;
  transition: all 0.1s ease 0.5s;
}

.action-btn {
  font-size: 0.875rem;
  border: 1px solid #000;
  padding: 7px 15px;
  border-radius: 10px;
}

.checked-class {
  border: 1px solid #EE2A7B;
}

.action-btn .icon {
  background-color: transparent;
  margin-right: 7px;
  display: flex;
}

.me-5 {
  margin-right: 20px;
}

.custom-checkbox {
  width: 15px;
  height: 15px;
  margin-left: 20px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}

.icon.icon-transfer:before,
.icon.icon-luggage-cart:before {
  font-size: 15px;
}

.custom-checkbox:checked {
  accent-color: #EE2A7B;
}

.btn-outline-primary:hover .icon,
.btn-outline-primary:focus .icon {
  background-color: transparent;
}

.transfer-btn-wrap {
  border-top: 1px solid #e8e8e8;
  margin-top: 1rem;
}

@media only screen and (max-width: 991px) {
  .Participants-wrapper {
    justify-content: end;
  }

  .total-wrapper {
    margin-top: 26px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mx-2 {
    margin: 0px;
  }

  .pax,
  .quantity {
    max-width: 100% !important;
  }

  .add-btn {
    margin: 10px 0px;
  }

  .total-wrapper {
    margin-top: 36px !important;
  }
}

@media only screen and (max-width: 580px) {
  .participants-wrap {
    display: flex;
    flex-direction: column;
  }

  .name-input,
  .age-input {
    width: 100%;
  }
}

.span-disabled {
  color: var(--uq-gray-500);
}
</style>
