<template>
  <div>
    <div class="row gx-16 mb-16">
      <v-form-input
        :id="`template-day-item-resource-${tourDayItemResource.id}-update-observations`"
        v-model="observations"
        :label="$t('general.shared.supplier_observations')"
        :yup-errors-variable="errors.observations"
        autocomplete="off"
        class="mb-24"
        form-type="outline"
        input-type="textarea"
        label-class="text-sm"
        rows="5"
        @update:modelValue="emitResourceData"
      />

      <div :id="`accordionAdvancedOptions${index}`" class="accordion mb-8">
        <div class="accordion-item bg-transparent border rounded-xs">
          <div :id="`headingAdvancedOptions${index}`" class="accordion-header">
            <button
              :aria-controls="`collapseAdvancedOptions${index}`"
              :data-bs-target="`#collapseAdvancedOptions${index}`"
              aria-expanded="false"
              class="accordion-button collapsed text-sm fw-medium bg-transparent rounded-xs p-16"
              data-bs-toggle="collapse"
              type="button">
              {{ $t('tourBuilder.advancedOptions') }}
            </button>
          </div>

          <div
            :id="`collapseAdvancedOptions${index}`"
            :aria-labelledby="`headingAdvancedOptions${index}`"
            :data-bs-parent="`#accordionAdvancedOptions${index}`"
            class="accordion-collapse collapse">
            <div class="accordion-body p-16">
              <v-checkbox
                :id="`template-day-item-resource-create-optional-${index}`"
                v-model="isOptional"
                :for-key="`template-day-item-resource-create-optional-${index}`"
                :label="$t('general.shared.optionalQuoteItem')"
                :left="true"
                class="form-check-inline me-32"
                label-class="text-sm"
                @update:modelValue="emitResourceData"
              />

              <v-form-input
                id="template-day-item-resource-create-reserve"
                v-model="nReserve"
                :label="$t('general.shared.nReserve')"
                :yup-errors-variable="errors.nReserve"
                autocomplete="off"
                form-type="outline"
                label-class="text-sm"
                type="text"
                @update:modelValue="emitResourceData"
              />

              <v-input-address
                id="template-day-item-resource-create-origin"
                :get-address="true"
                :get-radius-bounds="true"
                :label="$t('general.shared.origin')"
                :radius-bounds="20000"
                :value="origin"
                label-class="text-sm"
                @address="setLocation($event, true)"
              />

              <v-input-address
                id="template-day-item-resource-create-destination"
                :get-address="true"
                :get-radius-bounds="true"
                :label="$t('general.shared.destination')"
                :radius-bounds="20000"
                :value="destination"
                label-class="text-sm"
                @address="setLocation($event, false)"
              />

              <v-form
                id="template-day-item-resource-create-init-hour"
                :label="$t('general.shared.initHour')"
                form-type="outline"
                label-class="text-sm"
              >
                <Datepicker
                  v-model="initHour"
                  :month-change-on-scroll="false"
                  auto-apply
                  class="form-datepicker"
                  input-class-name="form-control"
                  time-picker
                  @update:modelValue="emitResourceData"
                >
                  <template #input-icon>
                    <v-icon class="ps-12" icon="schedule"/>
                  </template>
                </Datepicker>
              </v-form>

              <v-form
                id="template-day-item-resource-create-end-hour"
                :label="$t('general.shared.endHour')"
                form-type="outline"
                label-class="text-sm"
              >
                <Datepicker
                  v-model="endHour"
                  :month-change-on-scroll="false"
                  auto-apply
                  class="form-datepicker"
                  input-class-name="form-control"
                  time-picker
                  @update:modelValue="emitResourceData"
                >
                  <template #input-icon>
                    <v-icon class="ps-12" icon="schedule"/>
                  </template>
                </Datepicker>
              </v-form>
            </div>
          </div>
        </div>
      </div>

      <div class="item-resource-media-container">
        <div class="mb-16">
          <p class="text-lg fw-semi mb-8">{{ $t('general.shared.media') }}</p>

          <v-uploader
            ref="uploader"
            :after-upload="onChange"
            :afterUpload="onChange"
            :headers="{ 'X-case': templateId }"
            route-prefix="api/s3"
            @removed="onChange"
          />
        </div>

        <div v-if="itemResourceMedia.length > 0" :id="`accordionItemResourceMedia${index}`" class="accordion">
          <div class="accordion-item bg-transparent border rounded-xs">
            <h2 :id="`headingItemResourceMedia${index}`" class="accordion-header">
              <button
                :aria-controls="`collapseItemResourceMedia${index}`"
                :data-bs-target="`#collapseItemResourceMedia${index}`"
                aria-expanded="true"
                class="accordion-button collapsed text-sm fw-medium bg-transparent rounded-xs p-16"
                data-bs-toggle="collapse"
                type="button">
                {{ $t('general.button.seeFiles') }}
              </button>
            </h2>

            <div
              :id="`collapseItemResourceMedia${index}`"
              :aria-labelledby="`headingItemResourceMedia${index}`"
              :data-bs-parent="`#accordionItemResourceMedia${index}`"
              class="accordion-collapse collapse">
              <div class="accordion-body p-16">
                <div
                  v-for="file in itemResourceMedia"
                  :key="file.id"
                  class="resource-file d-flex align-items-center gap-8"
                >
                  <span class="file-name overflow-hidden d-inline-block w-100 white-space-nowrap text-sm">
                    {{ file.name }}
                  </span>

                  <div class="d-flex justify-content-end align-items-center">
                    <v-button
                      class="shadow-none p-4"
                      variant="icon"
                      @click="downloadMedia(file.id)"
                    >
                      <v-icon icon="download"/>
                    </v-button>

                    <v-button
                      class="shadow-none p-4"
                      variant="icon"
                      @click="removeMedia(file)"
                    >
                      <v-icon icon="trash"/>
                    </v-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="updateView" class="d-flex justify-content-end mt-12">
      <v-button
        :disabled="!meta.valid"
        class="btn-submit-form btn-icon w-fit align-self-end"
        size="sm"
        variant="primary"
        @click="updateTemplateDayItemResource(tourDayItemResource)"
      >
        {{ $t('general.button.save') }}
        <v-icon icon="arrow-right" size="sm" space="ms-12"/>
      </v-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { addDays, isEqual, set } from 'date-fns';
import { mapState } from 'pinia';
import { VCheckbox, VForm, VFormInput } from '@uniqoders/form';
import { useUserStore } from '@/stores/user';
import useConcepts from '@/helpers/Concepts';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import api from '@/api';
import { useAppStore } from '@/stores/app';
import VUploader from '@/components/vendor/upload/VUploader.vue';
import VInputAddress from '@/components/vendor/basic/form/VInputAddress.vue';
import Media from '@/api/objects/Media';

export default defineComponent({
  name: 'TheTemplateDayItemDetailsResourceForm',
  components: {
    VUploader,
    VInputAddress,
    VFormInput,
    Datepicker,
    VButton,
    VForm,
    VIcon,
    VCheckbox,
  },
  emits: ['closed', 'emitReloadTour', 'refreshResourceData', 'saved'],
  props: {
    data: {
      type: Object,
      required: true,
    },
    updateView: {
      type: Boolean,
      required: false,
      default: false,
    },
    agency: {
      type: String,
      required: false,
    },
    templateId: {
      type: Number,
      required: false,
    },
    tourDayId: {
      type: Number,
      required: false,
    },
    tourDayItemId: {
      type: Number,
      required: false,
    },
    index: {
      type: [Number, String],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const { t } = useI18n();


    let rules = yup.object({});

    rules = yup.object({
      observations: yup.string()
        .nullable()
        .label(t('general.shared.supplier_observations')),
      nReserve: yup.string()
        .nullable()
        .label(t('general.shared.nReserve')),
      origin: yup.string()
        .nullable()
        .label(t('general.shared.origin')),
      destination: yup.string()
        .nullable()
        .label(t('general.shared.destination')),
      initHour: yup.object()
        .nullable()
        .label(t('general.shared.initHour')),
      endHour: yup.object()
        .nullable()
        .label(t('general.shared.endHour')),
      isOptional: yup.string()
        .nullable()
        .label(t('general.shared.optional')),
    });

    const initialValues: Record<string, any> = {
      observations: props.data.observations,
      nReserve: props.data.nReserve,
      origin: props.data.origin,
      destination: props.data.destination,
      isOptional: props.data.isOptional,
    };

    if (!!props.data.initHour) {
      const time = props.data.initHour.split(':');

      initialValues.initHour = {
        hours: time[0],
        minutes: time[1],
      };
    }

    if (!!props.data.endHour) {
      const time = props.data.endHour.split(':');

      initialValues.endHour = {
        hours: time[0],
        minutes: time[1],
      };
    }

    const form = useFormValidation(rules, initialValues);

    const { value: observations } = useField('observations');
    const { value: nReserve } = useField('nReserve');
    const { value: origin } = useField('origin');
    const { value: destination } = useField('destination');
    const { value: initHour } = useField<any>('initHour');
    const { value: endHour } = useField<any>('endHour');
    const { value: isOptional } = useField('isOptional');

    return {
      userStore,
      ...useConcepts(),
      ...form,
      observations,
      nReserve,
      origin,
      destination,
      initHour,
      endHour,
      isOptional,
    };
  },
  data() {
    return {
      media: [] as any,
      tourDayItemResource: this.data,
      highlightedDates: [] as Date[],
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    ...mapState(useUserStore, ['currentTourDayItem']),
    ...mapState(useUserStore, ['currentTemplate']),
    mediaUuids() {
      return this.media.map((media: any) => ({
        uuid: media.attributes.uuid,
        name: media.attributes.name,
      }));
    },
    itemResourceMedia() {
      if (!!this.currentTourDayItem
        && !!this.currentTourDayItem.resources
        && this.currentTourDayItem.resources.length > 0) {
        const itemResource = this.currentTourDayItem.resources.find(
          (resource) => resource.id === this.tourDayItemResource.id);

        if (!!itemResource) {
          return itemResource.media;
        }
      }

      return [];
    },
  },
  watch: {
    async data(value: any) {
      this.tourDayItemResource = value;
    },
  },
  methods: {
    onChange() {
      const { uploader }: any = this.$refs;

      if (!!uploader) {
        this.media = uploader.mediaLibrary.state.media;

        this.emitResourceData();
      }
    },
    async updateTemplateDayItemResource(tourDayItemResource: Record<string, any>) {
      if (!!this.agency && !!this.templateId && !!this.tourDayId && !!this.tourDayItemId) {
        try {
          let endHour = '';
          let initHour = '';

          if (!!this.endHour) {
            const hours = this.endHour.hours.toString().split('').length === 1 ? `0${ this.endHour.hours }` : this.endHour.hours;
            const minutes = this.endHour.minutes.toString().split('').length === 1 ? `0${ this.endHour.minutes }` : this.endHour.minutes;
            endHour = `${ hours }:${ minutes }`;
          }
          if (!!this.initHour) {
            const hours = this.initHour.hours.toString().split('').length === 1 ? `0${ this.initHour.hours }` : this.initHour.hours;
            const minutes = this.initHour.minutes.toString().split('').length === 1 ? `0${ this.initHour.minutes }` : this.initHour.minutes;
            initHour = `${ hours }:${ minutes }`;
          }

          const resource = {
            observations: this.observations,
            n_reserve: this.nReserve,
            origin: this.origin,
            destination: this.destination,
            init_hour: initHour,
            end_hour: endHour,
            is_optional: this.isOptional,
          };

          const response = await api.templateDayItemResource.update(
            this.agency,
            this.templateId,
            this.tourDayId,
            this.tourDayItemId,
            tourDayItemResource.id,
            resource,
          );

          if (!!this.mediaUuids && this.mediaUuids.length > 0) {
            const media = {
              media: this.mediaUuids,
              model_type: 'tour_day_item_resource',
              collection: 'item_resource',
              model_id: tourDayItemResource.id,
            };

            await api.media.confirm('s3', media);

            this.mediaUuids = [];

            const { uploader }: any = this.$refs;

            if (!!uploader) {
              uploader.mediaLibrary.state.media = [];
            }
          }

          this.$emit('emitReloadTour');

          const data = {
            agency: this.agency,
            templateId: this.templateId,
            templateDayId: this.tourDayId,
            templateDayItemId: this.tourDayItemId,
          };

          await this.userStore.setCurrentTemplateDayItem(data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

        } catch (e: any) {
          console.error(e);

          this.$toast.error(e.response.data.message);
        }
      }
    },
    emitResourceData() {
      this.$emit('refreshResourceData', {
        id: this.tourDayItemResource.id,
        observations: this.observations,
        nReserve: this.nReserve,
        origin: this.origin,
        destination: this.destination,
        initHour: !!this.initHour ? `${ this.initHour.hours }:${ this.initHour.minutes }` : '',
        endHour: !!this.endHour ? `${ this.endHour.hours }:${ this.endHour.minutes }` : '',
        isOptional: this.isOptional,
        media: this.mediaUuids,
      });
    },
    async setLocation(address: Record<string, any>, isOrigin = true) {
      if (isOrigin) {
        this.origin = address.name;
      } else {
        this.destination = address.name;
      }
    },
    async downloadMedia(fileId: number) {
      try {
        const response = await api.media.download('s3', fileId);

        window.open(response, '_blank');
      } catch (e: any) {
        console.error(e);

        this.$toast.error(e.response.data.message);
      }
    },
    async removeMedia(file: Media) {
      await this.$modal.delete({
        title: this.$t('clientTour.invoice.removeTitle'),
        text: this.$t('clientTour.invoice.removeText', { name: file.name }),
        deleteButtonText: this.$t('general.button.remove'),
        deleteButtonLoadingText: this.$t('general.button.removing'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doRemoveMedia(file.id),
      });
    },
    async doRemoveMedia(fileId: string | number) {
      try {
        await api.media.delete('s3', fileId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        this.reloadAll();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    reloadAll() {
      if (!!this.currentTourDayItem) {
        const data = {
          agency: this.agency,
          templateId: this.templateId,
          templateDayId: this.currentTourDayItem.tourDayId,
          templateDayItemId: this.currentTourDayItem.id,
        };

        this.userStore.setCurrentTemplateDayItem(data);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.resource-file {
  position: relative;

  &:not(:last-child) {
    margin-bottom: .25rem;
    padding-bottom: .25rem;
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: var(--uq-gray-300);
  }

  .file-name {
    text-overflow: ellipsis;
  }
}
</style>
