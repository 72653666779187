<template>
  <v-off-canvas
    id="supplier-rates-offcanvas"
    :backdrop-static="false"
    offcanvas-class="item-details offcanvas-end double-offcanvas w-100-offcanvas offcanvas-fullscreen-xl-down"
    @closed="$emit('closed')"
  >
    <off-canvas-header>
      <div class="d-flex align-items-center">
        <v-icon class="text-primary" icon="info" size="xxl" space="me-8" />

        <h5 id="supplier-rates-offcanvas-label" class="offcanvas-title">
          {{ $t("supplier.rates") }}
        </h5>
      </div>
    </off-canvas-header>

    <off-canvas-body class="w-100">
      <p class="text-sm fw-medium">
        {{ $t("supplier.rate.description") }}
      </p>

      <v-loader v-if="status === 'loading'" />

      <div v-else class="rates-container">
        <div class="table-container" role="table">
          <div class="d-flex table-header" role="rowgroup">
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.product") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.type") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.category") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.initDate") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.endDate") }}</div>
            <div class="table-flex-row" role="columnheader">{{ $t("supplier.rate.price") }}</div>
            <div class="table-flex-row actions" role="columnheader" />
          </div>

          <div
            v-for="resource in supplierResources"
            :key="resource.id"
            class="d-flex table-content-row flex-column"
            role="rowgroup"
          >
            <div class="d-flex">
              <div class="table-flex-row" role="cell">{{ resource.name }}</div>

              <div class="d-flex flex-column table-type w-100">
                <template v-if="!!resource.types && resource.types.length > 0">
                  <template v-for="(type, index) in resource.types" :key="index">
                    <div class="d-flex table-row">
                      <div class="table-flex-row" role="cell">
                        {{ fnFindConceptByKey("supplier_resource_price.type", type.key).name }}
                      </div>

                      <div class="d-flex flex-column table-category w-100">
                        <template v-for="(category, index) in type.categories" :key="index">
                          <div class="d-flex table-row">
                            <div class="table-flex-row" role="cell">
                              {{ fnFindConceptByKey("supplier_resource_price.category", category.key).name }}
                            </div>

                            <div class="d-flex flex-column table-price w-100">
                              <template v-for="(price, index) in category.prices" :key="index">
                                <div class="d-flex table-row w-100">
                                  <div class="table-flex-row" role="cell">
                                    {{ $str.formatDateTime(price.initDate, settings.formatDate) }}
                                  </div>

                                  <div class="table-flex-row" role="cell">
                                    {{
                                      !!price.endDate ? $str.formatDateTime(price.endDate, settings.formatDate) : "-"
                                    }}
                                  </div>

                                  <div class="table-flex-row" role="cell">{{ price.price }}</div>
                                  <div class="table-flex-row actions" role="cell">
                                    <v-dropdown
                                      :id="`tour-dropdown-${price.id}`"
                                      button-classes="text-sm fw-medium bg-transparent shadow-none btn-menu"
                                      class="ms-8"
                                      dropdown-classes="dropdown-tour dropdown-menu-end"
                                    >
                                      <template #content>
                                        <li class="dropdown-list p-0">
                                          <a
                                            :aria-label="$t('supplier.rate.update')"
                                            class="dropdown-item px-8 py-16"
                                            href="#"
                                            @click.prevent="setCurrentPrice(price, resource.id)"
                                          >
                                            <span>{{ $t("supplier.rate.update") }}</span>
                                          </a>
                                        </li>

                                        <li class="dropdown-list p-0">
                                          <a
                                            :aria-label="$t('supplier.rate.delete')"
                                            class="dropdown-item px-8 py-16"
                                            href="#"
                                            @click.prevent="deletePrice(price, resource)"
                                          >
                                            <span>{{ $t("supplier.rate.delete") }}</span>
                                          </a>
                                        </li>
                                        <li class="dropdown-list p-0">
                                          <a
                                            :aria-label="$t('supplier.rate.delete')"
                                            class="dropdown-item px-8 py-16"
                                            :class="{ 'disabled-link': supplierResources.length === 1 }"
                                            href="#"
                                            @click.prevent="openDuplicateModal('', price, resource.id)"
                                          >
                                            <span>{{ $t("supplier.rate.duplicate") }}</span>
                                          </a>
                                        </li>
                                      </template>
                                    </v-dropdown>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>

                      <div class="d-flex flex-column table-category w-100" />
                    </div>
                  </template>
                </template>

                <div
                  class="d-flex justify-content-between align-items-center h-100 actions custom-padding-btn"
                  role="cell"
                >
                  <v-button
                    class="btn-icon p-4 rounded-circle"
                    size="sm"
                    variant="gradient"
                    @click="setCurrentResource(resource.id)"
                  >
                    <v-icon icon="plus" />
                  </v-button>
                  <v-button
                    class="btn-icon"
                    size="sm"
                    variant="outline-default"
                    :disabled="supplierResources.length === 1 || resource.types.length === 0"
                    @click.prevent="openDuplicateModal('block', resource, resource.id)"
                  >
                    {{ $t("supplier.rate.duplicateBlock") }}
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <the-supplier-details-rates-create-modal
        v-if="modalToShow === 'the-supplier-details-rates-create-modal'"
        :price-data="currentPrice"
        :resource="currentResource"
        @closed="
          closeModal();
          currentPrice = null;
        "
        @emit-reload-supplier-rates="reloadRates"
      />
    </off-canvas-body>
  </v-off-canvas>
  <the-supplier-duplicate-price-modal
    v-if="duplicatePrice"
    @closed="closeDuplicateModal"
    :supplier-resources="supplierResources"
    :duplicate-price-data="duplicatePriceData"
    :duplicate-block-string="duplicateBlockString"
    :selected-resouce-id="selectedResouceId"
    @emit-reload-supplier-rates="reloadRates"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import VOffCanvas from '@/components/vendor/basic/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/basic/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/basic/offcanvas/OffCanvasBody.vue';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import Supplier from '@/api/objects/Supplier';
import { findConceptByKey } from '@/helpers/ConceptHelper';
import useConcepts from '@/helpers/Concepts';
import VDropdown from '@/components/vendor/basic/dropdown/VDropdown.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useModalUtils from '@/helpers/ModalUtils';
import TheSupplierDetailsRatesCreateModal from '@/components/supplier/parts/TheSupplierDetailsRatesCreateModal.vue';
import TheSupplierDuplicatePriceModal from '@/components/supplier/parts/TheSupplierDuplicatePriceModal.vue';
import SupplierResourcePrice from '@/api/objects/SupplierResourcePrice';
import api from '@/api';
import VLoader from '@/components/vendor/loader/VLoader.vue';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheSupplierDetailsRatesOffCanvas',
  components: {
    VLoader,
    TheSupplierDetailsRatesCreateModal,
    TheSupplierDuplicatePriceModal,
    VDropdown,
    VIcon,
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    VButton,
  },
  emits: ['closed', 'reloadSupplier'],
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
      ...useConcepts(),
    };
  },
  data() {
    return {
      supplier: {} as Supplier,
      status: 'loading' as string,
      supplierResources: [] as any[],
      currentPrice: null as SupplierResourcePrice | null,
      currentResource: null as number | null,
      duplicatePrice: false as boolean | null,
      duplicatePriceData: [] as any[],
      duplicateBlockString: '' as string,
      selectedResouceId: 0 as number,
      fnFindConceptByKey: findConceptByKey,
    };
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
  },
  async created() {
    this.status = 'loading';

    await this.reloadRates();

    this.status = 'loaded';
  },
  methods: {
    async reloadRates() {
      await this.loadSupplier();
      await this.mapSupplierResources();
    },
    async loadSupplier() {
      this.supplier = await api.supplier.retrieve(this.supplierId);
    },
    mapSupplierResources() {
      this.supplierResources = this.supplier.resources.map((resource) => {
        const allResourcePriceTypes = resource.prices?.map((item) => item.type);

        const resourcePriceTypeKeys = allResourcePriceTypes?.filter(
          (element, index) => allResourcePriceTypes.indexOf(element) === index,
        );

        return {
          id: resource.id,
          name: resource.name,
          types: resourcePriceTypeKeys?.map((resourcePriceTypeKey) => {
            const pricesCurrentType = resource.prices.filter((price: any) => price.type === resourcePriceTypeKey);

            const allResourcePriceCategories = pricesCurrentType.map((item) => item.category);

            const resourcePriceCategoryKeys = allResourcePriceCategories.filter(
              (element, index) => allResourcePriceCategories.indexOf(element) === index,
            );

            return {
              key: resourcePriceTypeKey,
              categories: resourcePriceCategoryKeys.map((resourcePriceCategoryKey) => {
                const prices = resource.prices.filter(
                  (price: any) => price.type === resourcePriceTypeKey && price.category === resourcePriceCategoryKey,
                );

                prices.sort((a: any, b: any) => {
                  if (a.initDate < b.initDate) {
                    return -1;
                  }

                  if (a.initDate > b.initDate) {
                    return 1;
                  }

                  return 0;
                });

                return {
                  key: resourcePriceCategoryKey,
                  prices,
                };
              }),
            };
          }),
        };
      });
    },
    setCurrentPrice(price: SupplierResourcePrice, resourceId: number) {
      this.currentPrice = price;
      this.currentResource = resourceId;

      this.openModal('the-supplier-details-rates-create-modal');
    },
    setCurrentResource(resourceId: number) {
      this.currentResource = resourceId;

      this.openModal('the-supplier-details-rates-create-modal');
    },
    async deletePrice(price: SupplierResourcePrice, resource: any) {
      await this.$modal.delete({
        title: this.$t('supplier.rate.removeTitle'),
        text: this.$t('supplier.rate.removeText', {
          product: resource.name,
        }),
        deleteButtonText: this.$t('general.button.delete'),
        rightButtonClasses: 'btn-tertiary',
        deleteButtonCallback: () => this.doDeletePrice(resource.id, price.id),
      });
    },
    async doDeletePrice(resourceId: number, priceId: string | number) {
      try {
        await api.supplierResourcePrice.delete(resourceId, priceId);

        this.$toast.success(this.$t('general.shared.savedChanges'));

        await this.reloadRates();
      } catch (e: any) {
        console.error(e.response.data);

        this.$toast.error(e.response.data.message);
      }
    },
    openDuplicateModal(block: string, priceData: any, id:number) {
      this.duplicateBlockString = block;
      this.duplicatePriceData = priceData;
      this.selectedResouceId = id;
      this.duplicatePrice = true;
    },
    closeDuplicateModal() {
      this.duplicatePrice = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.rates-container {
  width: auto;
  display: flex;
  max-width: 100%;
}

#supplier-rates-offcanvas {
  $general-width: 8rem;

  .table-container {
    padding-right: 1.5rem;

    .table-flex-row {
      align-items: center;
      display: flex;
      min-width: $general-width;
      padding: 1.125rem 0.375rem;

      &:first-child {
        width: 100%;
        max-width: 14rem;
      }

      &.actions {
        width: fit-content;
      }
    }

    &:not(.only-view) .table-type > .table-row,
    &.only-view .table-type > .table-row:not(:last-child),
    .table-category > .table-row:not(:last-child),
    .table-price > .table-row:not(:last-child) {
      border-bottom: 1px solid var(--uq-gray-400);
    }
  }
}

.table-content-row {
  border: 1px solid var(--uq-gray-400);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.custom-padding-btn {
  padding: 1.125rem 0.375rem;
}

.disabled-link {
  pointer-events: none;
  color: #6c757d;
  cursor: not-allowed;
}
</style>
