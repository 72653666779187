<template>
  <div :id="`accordion-${id}`" class="information-container accordion break-avoid mb-16">
    <div :id="`head-${id}`" class="cursor-pointer">
      <div
        :aria-controls="`collapse-${id}`"
        :data-bs-target="`#collapse-${id}`"
        aria-expanded="true"
        class="accordion-button fw-semi d-flex align-items-center bg-transparent
          px-24 px-lg-32 py-16 py-lg-20 h-100 shadow-none"
        data-bs-toggle="collapse"
      >
        <div class="d-flex">
          <v-icon :icon="titleIcon" size="xl" space="me-12" />

          <div v-if="!!title">
            <template v-if="title === 'Stage Information'">
              {{ $t("travelBook.stageInformation") }}
            </template>
            <template v-else>
              {{ title }}
            </template>

            <template
              v-if="!!supplierName && dayItem.type !== 'general_information' && dayItem.type !== 'stage_information'"
            >
              - {{ supplierName }}
            </template>
          </div>

          <template v-else>
            <div v-if="isLocationInfo">
              {{ $t("travelBook.whatToDoLocation", { location: location }) }}
            </div>

            <div v-else-if="!isStage">
              {{ $t("travelBook.dayInformation") }}
            </div>

            <div v-else>
              {{ $t("travelBook.stageInformation") }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="dayItem?.type === 'stage_information' || dayItem?.type === 'general_information'"
      class="data-container-info px-24 py-12 px-lg-32 py-lg-12 pt-lg-12"
    >
      <template v-for="itemResource in resources" :key="itemResource.id">
        <div>
          <span class="info-title fw-medium me-4"> {{ $t("general.shared.location") }}: </span>
          {{ itemResource?.resource?.title }}
        </div>
        <div v-if="!!itemResource?.resource?.stageLength">
          <span class="info-title fw-medium me-4">
            {{
              $t("travelBook.distanceNumberKm", {
                number: itemResource?.resource?.stageLength,
              })
            }}
          </span>
        </div>
      </template>
    </div>

    <div
      :id="`collapse-${id}`"
      :aria-labelledby="`head-${id}`"
      :data-bs-parent="`#accordion-${id}`"
      class="collapse show"
    >
      <div
        v-if="message || isLocationInfo || !!mapUrl || !!siteUrl || !!url || (!!imagesAux && imagesAux.length > 0)"
        class="row gx-0 break-avoid"
      >
        <div
          :class="[
            {
              'col-lg-6': !!imagesAux && imagesAux.length > 0,
            },
            index % 2 === 0 ? 'left order-1' : 'right order-2',
          ]"
          class="col-12"
        >
          <div class="data-container px-24 py-12 px-lg-32 py-lg-32 pt-lg-12 h-100">
            <div class="d-flex flex-column h-100">
              <template
                v-if="
                  showDataItem &&
                  !!supplierName &&
                  (dayItem.type === 'general_information' || dayItem.type === 'stage_information')
                "
              >
                <div
                  v-for="itemResource in resources"
                  :key="itemResource.id"
                  class="d-flex flex-column text-sm mb-8 mb-sm-0"
                >
                  <template
                    v-if="
                      itemResource?.resource?.type === 'stage_information' ||
                      itemResource?.resource?.type === 'general_information'
                    "
                  >
                    <div v-for="(lang, index) in itemResource?.resource?.translations" :key="index">
                      <template v-if="currentLanguage === lang.locale && lang?.description">
                        <div class="message d-flex justify-content-center flex-column pb-32">
                          <div
                            :id="`collapseText-${itemResource.id}`"
                            :class="{ show: lang?.description?.length <= 500 }"
                            class="collapse collapsable-text"
                          >
                            <div v-html="lang?.description?.trim()?.replace(/\s+/g, ' ')" />
                          </div>

                          <v-button
                            v-if="lang?.description?.length > 500"
                            ref="collapseButton"
                            :aria-controls="`collapseText-${itemResource.id}`"
                            :data-bs-target="`#collapseText-${itemResource.id}`"
                            aria-expanded="false"
                            class="btn-sm mx-auto"
                            data-bs-toggle="collapse"
                            type="button"
                            variant="primary"
                            @click="toggleCollapsedText"
                          >
                            {{ isLargeTextCollapsed ? $t("general.button.viewMore") : $t("general.button.viewLess") }}
                          </v-button>
                        </div>
                      </template>
                    </div>
                  </template>
                  <!-- <template v-else>
                    <div class="d-flex align-items-center text-sm mb-8 mb-sm-0">
                      <div>{{ itemResource.resource.name }}</div>

                      <span class="dot-divider" />

                      <div>
                        <span>{{ $str.formatDateTime(tourDay.date, settings.formatDate) }}</span>

                        <template v-if="!!itemResource.endDate && tourDay.date !== itemResource.endDate">
                          <span class="mx-8">-</span>
                          <span>{{ $str.formatDateTime(itemResource.endDate, settings.formatDate) }}</span>
                        </template>
                      </div>

                      <template
                        v-if="
                          itemResource.origin ||
                          itemResource.initHour ||
                          itemResource.destination ||
                          itemResource.endHour
                        "
                      >
                        <span class="dot-divider" />

                        <div>
                          <span v-if="!!itemResource.origin">
                            {{ $t("general.shared.origin") }}: {{ itemResource.origin }}
                          </span>

                          <span v-if="!!itemResource.initHour">
                            <template v-if="!itemResource.origin">
                              {{ $t("general.shared.initHour") }}: {{ itemResource.initHour }}
                            </template>

                            <template v-else> ({{ itemResource.initHour }})</template>
                          </span>

                          <span
                            v-if="
                              (itemResource.origin || itemResource.initHour) &&
                              (itemResource.destination || itemResource.endHour)
                            "
                            class="mx-8"
                            >-</span
                          >

                          <span v-if="!!itemResource.destination">
                            {{ $t("general.shared.destination") }}: {{ itemResource.destination }}
                          </span>

                          <span v-if="!!itemResource.endHour">
                            <template v-if="!itemResource.destination">
                              {{ $t("general.shared.endHour") }}: {{ itemResource.endHour }}
                            </template>

                            <template v-else> ({{ itemResource.endHour }})</template>
                          </span>
                        </div>
                      </template>
                    </div>
                    <div v-if="!!itemResource.clientObservations">
                      <b>{{ $t("general.shared.client_observations") }}</b> : {{ itemResource.clientObservations }}
                    </div>
                    <hr />
                  </template> -->
                </div>
              </template>
              <template v-else>
                <div class="d-flex flex-column justify-content-between h-100">
                  <div v-if="!!message" class="message d-flex justify-content-center flex-column pb-32">
                    <div
                      :id="`collapseText-${id}`"
                      :class="{ show: messageLenght <= 500 }"
                      class="collapse collapsable-text"
                    >
                      <div v-html="message" />
                    </div>

                    <v-button
                      v-if="messageLenght > 500"
                      ref="collapseButton"
                      :aria-controls="`collapseText-${id}`"
                      :data-bs-target="`#collapseText-${id}`"
                      aria-expanded="false"
                      class="btn-sm mx-auto"
                      data-bs-toggle="collapse"
                      type="button"
                      variant="primary"
                      @click="toggleCollapsedText"
                    >
                      {{ isLargeTextCollapsed ? $t("general.button.viewMore") : $t("general.button.viewLess") }}
                    </v-button>
                  </div>

                  <div
                    v-if="isLocationInfo || !!mapUrl || !!siteUrl || !!url"
                    class="d-flex align-items-center justify-content-between mt-8"
                  >
                    <div class="links-container d-flex align-items-center hide-print">
                      <div v-if="!!mapUrl" class="text-sm">
                        <a
                          :aria-label="$t('travelBook.urlToLocationMap', { location: location })"
                          :href="mapUrl"
                          class="fw-medium btn-link p-0"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ $t("travelBook.viewGoogleMaps") }}
                        </a>
                      </div>

                      <span v-if="!!mapUrl && !!siteUrl" class="dot-divider" />

                      <div v-if="!!siteUrl" class="text-sm">
                        <a
                          :aria-label="$t('travelBook.urlToLocationWeb', { location: location })"
                          :href="siteUrl"
                          class="fw-medium btn-link p-0"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ $t("travelBook.viewWebsite") }}
                        </a>
                      </div>

                      <div v-if="!!url" class="text-sm mb-4">
                        <a
                          :aria-label="$t('travelBook.linkAboutDay')"
                          :href="url"
                          class="fw-medium btn-link p-0"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ $t("travelBook.viewWebsite") }}
                        </a>
                      </div>
                    </div>

                    <div class="links-container only-print">
                      <div v-if="!!mapUrl" class="text-sm mb-4">
                        <span class="me-4">{{ $t("travelBook.viewGoogleMaps") }}:</span>

                        <a
                          :aria-label="$t('travelBook.urlToLocationMap', { location: location })"
                          :href="mapUrl"
                          class="fw-medium btn-link p-0"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ mapUrl }}
                        </a>
                      </div>

                      <div v-if="!!siteUrl" class="text-sm mb-4">
                        <span class="me-4">{{ $t("travelBook.viewWebsite") }}:</span>

                        <a
                          :aria-label="$t('travelBook.urlToLocationWeb', { location: location })"
                          :href="siteUrl"
                          class="fw-medium btn-link p-0"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ siteUrl }}
                        </a>
                      </div>

                      <div v-if="!!url" class="text-sm">
                        <span class="me-4">{{ $t("travelBook.viewWebsite") }}:</span>

                        <a
                          :aria-label="$t('travelBook.linkAboutDay')"
                          :href="url"
                          class="fw-medium btn-link p-0"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {{ url }}
                        </a>
                      </div>
                    </div>

                    <!-- TODO: do not remove this uncommented code when needed -->
                    <!-- <v-button
                    v-if="isLocationInfo"
                    class="hide-print"
                    size="xs"
                    variant="primary"
                    @click="openModal('the-travel-book-location-resources-modal')"
                  >
                    {{ $t('travelBook.guidebookLocation', { location: location }) }}
                  </v-button> -->
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div
          :class="[
            {
              'col-lg-6': !!imagesAux && imagesAux.length > 0,
              'd-none': !imagesAux || imagesAux.length === 0,
            },
            index % 2 === 0 ? 'right order-2' : 'left order-1',
          ]"
          class="col-12"
        >
          <div class="w-100 h-100">
            <swiper
              :loop="true"
              :modules="modules"
              :navigation="true"
              :pagination="{
                type: 'fraction',
              }"
              class="mySwiper hide-print"
            >
              <swiper-slide v-for="image in imagesAux" :key="image.id">
                <v-picture class="image-container">
                  <img :alt="image.name" :src="image.storageUrl" />
                </v-picture>
              </swiper-slide>
            </swiper>

            <div v-if="!!imagesAux && imagesAux.length > 0" class="only-print p-12">
              <v-picture class="image-container">
                <img :alt="imagesAux[0].name" :src="imagesAux[0].storageUrl" />
              </v-picture>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="data-container px-24 py-12 px-lg-32 py-lg-32 pt-lg-12 h-100">
        <div class="d-flex flex-column h-100">
          <template v-if="showDataItem && !!supplierName">
            <div
              v-for="itemResource in resources"
              :key="itemResource.id"
              class="d-flex flex-column text-sm mb-8 mb-sm-0"
            >
              <template
                v-if="
                  itemResource?.resource?.type === 'stage_information' ||
                  itemResource?.resource?.type === 'general_information'
                "
              >
                <div v-for="(lang, index) in itemResource?.resource?.translations" :key="index">
                  <template v-if="currentLanguage === lang.locale && lang?.description">
                    <div class="message d-flex justify-content-center flex-column pb-32">
                      <div
                        :id="`collapseText-${itemResource.id}`"
                        :class="{ show: lang?.description?.length <= 500 }"
                        class="collapse collapsable-text"
                      >
                        <div  v-html="lang?.description?.trim()?.replace(/\s+/g, ' ')" />
                      </div>

                      <v-button
                        v-if="lang?.description?.length > 500"
                        ref="collapseButton"
                        :aria-controls="`collapseText-${itemResource.id}`"
                        :data-bs-target="`#collapseText-${itemResource.id}`"
                        aria-expanded="false"
                        class="btn-sm mx-auto"
                        data-bs-toggle="collapse"
                        type="button"
                        variant="primary"
                        @click="toggleCollapsedText"
                      >
                        {{ isLargeTextCollapsed ? $t("general.button.viewMore") : $t("general.button.viewLess") }}
                      </v-button>
                    </div>
                  </template>
                </div>
              </template>
              <!-- <template v-else>
                <div class="d-flex align-items-center text-sm mb-8 mb-sm-0">
                  <div>{{ itemResource.resource.name }}</div>

                  <span class="dot-divider" />

                  <div>
                    <span>{{ $str.formatDateTime(tourDay.date, settings.formatDate) }}</span>

                    <template v-if="!!itemResource.endDate && tourDay.date !== itemResource.endDate">
                      <span class="mx-8">-</span>
                      <span>{{ $str.formatDateTime(itemResource.endDate, settings.formatDate) }}</span>
                    </template>
                  </div>

                  <template
                    v-if="
                      itemResource.origin || itemResource.initHour || itemResource.destination || itemResource.endHour
                    "
                  >
                    <span class="dot-divider" />

                    <div>
                      <span v-if="!!itemResource.origin">
                        {{ $t("general.shared.origin") }}: {{ itemResource.origin }}
                      </span>

                      <span v-if="!!itemResource.initHour">
                        <template v-if="!itemResource.origin">
                          {{ $t("general.shared.initHour") }}: {{ itemResource.initHour }}
                        </template>

                        <template v-else> ({{ itemResource.initHour }})</template>
                      </span>

                      <span
                        v-if="
                          (itemResource.origin || itemResource.initHour) &&
                          (itemResource.destination || itemResource.endHour)
                        "
                        class="mx-8"
                        >-</span
                      >

                      <span v-if="!!itemResource.destination">
                        {{ $t("general.shared.destination") }}: {{ itemResource.destination }}
                      </span>

                      <span v-if="!!itemResource.endHour">
                        <template v-if="!itemResource.destination">
                          {{ $t("general.shared.endHour") }}: {{ itemResource.endHour }}
                        </template>

                        <template v-else> ({{ itemResource.endHour }})</template>
                      </span>
                    </div>
                  </template>
                </div>
                <div v-if="!!itemResource.clientObservations">
                  <b>{{ $t("general.shared.client_observations") }}</b> : {{ itemResource.clientObservations }}
                </div>
                <hr />
              </template> -->
            </div>
          </template>
        </div>
      </div>

      <div
        v-if="dayItem?.type === 'general_information' || dayItem?.type === 'stage_information'"
        class="data-container-info px-24 py-12 px-lg-32 py-lg-32 pt-lg-12"
      >
        <template v-for="itemResource in resources" :key="itemResource.resource">
          <div v-if="itemResource?.resource?.links" class="search-separator-container mb-8">
            <div class="separator-text-container">
              <span class="separator-text text-sm">{{ $t("general.shared.links") }}</span>
            </div>
          </div>
          <div v-if="itemResource?.resource?.links" class="text-sm d-flex flex-column py-4">
            <span class="fw-semi text-sm mb-8">{{ $t("general.shared.links") }}: </span>
            <template v-for="url in JSON.parse(itemResource?.resource?.links)" :key="url">
              <a :href="url" class="resource-link btn-link" rel="noopener noreferrer" target="_blank">
                {{ url }}
              </a>
            </template>
          </div>
        </template>
      </div>

      <div v-if="showDataItem && !!supplierName">
        <div class="data-container-info px-24 py-12 px-lg-32 py-lg-32 pt-lg-12">
          <div class="fw-semi d-flex align-items-center mb-16 break-avoid">
            <div
              v-if="!!supplierName && dayItem.type !== 'general_information' && dayItem.type !== 'stage_information'"
              class="text-uppercase"
            >
              {{ supplierName }} {{ $t("travelBook.information") }}
            </div>
          </div>

          <template
            v-if="!!supplierName && dayItem.type !== 'general_information' && dayItem.type !== 'stage_information'"
          >
            <template v-for="itemResource in resources" :key="itemResource.id">
              <div class="d-flex align-items-center text-sm mb-8 mb-sm-0">
                <div>{{ itemResource.resource.name }}</div>

                <span class="dot-divider" />

                <div>
                  <span>{{ $str.formatDateTime(tourDay.date, settings.formatDate) }}</span>

                  <template v-if="!!itemResource.endDate && tourDay.date !== itemResource.endDate">
                    <span class="mx-8">-</span>
                    <span>{{ $str.formatDateTime(itemResource.endDate, settings.formatDate) }}</span>
                  </template>
                </div>

                <template
                  v-if="
                    itemResource.origin || itemResource.initHour || itemResource.destination || itemResource.endHour
                  "
                >
                  <span class="dot-divider" />

                  <div>
                    <span v-if="!!itemResource.origin">
                      {{ $t("general.shared.origin") }}: {{ itemResource.origin }}
                    </span>

                    <span v-if="!!itemResource.initHour">
                      <template v-if="!itemResource.origin">
                        {{ $t("general.shared.initHour") }}: {{ itemResource.initHour }}
                      </template>

                      <template v-else> ({{ itemResource.initHour }})</template>
                    </span>

                    <span
                      v-if="
                        (itemResource.origin || itemResource.initHour) &&
                        (itemResource.destination || itemResource.endHour)
                      "
                      class="mx-8"
                    >-</span
                    >

                    <span v-if="!!itemResource.destination">
                      {{ $t("general.shared.destination") }}: {{ itemResource.destination }}
                    </span>

                    <span v-if="!!itemResource.endHour">
                      <template v-if="!itemResource.destination">
                        {{ $t("general.shared.endHour") }}: {{ itemResource.endHour }}
                      </template>

                      <template v-else> ({{ itemResource.endHour }})</template>
                    </span>
                  </div>
                </template>
              </div>
              <div v-if="!!itemResource.clientObservations" class="my-32">
                <b class="my-32">{{ $t("general.shared.client_observations") }}</b> :
                <p></p>
                <span v-html="itemResource.clientObservations" />
              </div>
              <hr />
            </template>
          </template>
          <div
            v-if="!!supplier && (!!supplier.phone || !!supplier.mapUrl || !!supplier.siteUrl)"
            class="contact-container break-avoid"
          >
            <div class="fw-semi text-sm mb-8">{{ $t("travelBook.contact") }}</div>

            <div v-if="!!supplier?.phone" class="d-flex align-items-center gap-16 mb-4">
              <div class="contact-data-title">{{ $t("general.shared.phone") }}</div>
              <div class="contact-data-value fw-semi text-xs">
                {{ supplier.phone }}
              </div>
            </div>

            <div class="d-flex align-items-center hide-print">
              <div v-if="!!supplier?.mapUrl" class="text-sm">
                <a
                  :aria-label="$t('travelBook.urlGoogleMapsOfSupplier', { supplier: supplier.name })"
                  :href="supplier.mapUrl"
                  class="contact-data-value fw-medium btn-link p-0"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ $t("travelBook.viewGoogleMaps") }}
                </a>
              </div>

              <span v-if="!!supplier?.mapUrl && !!supplier?.siteUrl" class="dot-divider" />

              <div v-if="!!supplier?.siteUrl" class="text-sm">
                <a
                  :aria-label="$t('travelBook.viewWebsiteOfSupplier', { supplier: supplier.name })"
                  :href="supplier.siteUrl"
                  class="contact-data-value fw-medium btn-link p-0"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ $t("travelBook.viewWebsite") }}
                </a>
              </div>
            </div>

            <div class="only-print">
              <div v-if="!!supplier?.mapUrl" class="text-sm mb-4">
                <span class="me-4">{{ $t("travelBook.viewGoogleMaps") }}: </span>

                <a
                  :aria-label="$t('travelBook.urlGoogleMapsOfSupplier', { supplier: supplier.name })"
                  :href="supplier.mapUrl"
                  class="contact-data-value fw-medium btn-link p-0"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ supplier.mapUrl }}
                </a>
              </div>

              <div v-if="!!supplier?.siteUrl" class="text-sm mb-4">
                <span class="me-4">{{ $t("travelBook.viewWebsite") }}: </span>

                <a
                  :aria-label="$t('travelBook.viewWebsiteOfSupplier', { supplier: supplier.name })"
                  :href="supplier.siteUrl"
                  class="contact-data-value fw-medium btn-link p-0"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ supplier.siteUrl }}
                </a>
              </div>
            </div>
          </div>

          <div v-if="!!supplier && !!supplier.contactAddress1" class="address-container break-avoid">
            <div class="fw-semi text-sm mb-8">{{ $t("travelBook.address") }}</div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium me-4">{{ $t("general.address.streetAddress") }}: </span>
              <span v-if="!!supplier?.contactAddress1">{{ supplier.contactAddress1 }}</span>
              <span v-else>-</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium me-4">{{ $t("general.address.aptSuit") }}: </span>
              <span v-if="!!supplier?.contactAddress2">{{ supplier.contactAddress2 }}</span>
              <span v-else>-</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium me-4">{{ $t("general.address.postalCode") }}: </span>
              <span v-if="!!supplier?.contactPostalCode">{{ supplier.contactPostalCode }}</span>
              <span v-else>-</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium me-4">{{ $t("general.address.country") }}: </span>
              <span v-if="!!supplier?.contactCountry">{{ supplier.contactCountry }}</span>
              <span v-else>-</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium me-4">{{ $t("general.address.city") }}: </span>
              <span v-if="!!supplier?.contactCity">{{ supplier.contactCity }}</span>
              <span v-else>-</span>
            </div>

            <div class="text-sm d-flex py-4">
              <span class="info-title fw-medium me-4">{{ $t("general.address.provinceStateRegion") }}: </span>
              <span v-if="!!supplier?.contactState">{{ supplier.contactState }}</span>
              <span v-else>-</span>
            </div>
          </div>

          <div v-if="showFiles" class="file-container">
            <div class="fw-semi text-sm mb-8">{{ $t("general.shared.files") }}</div>

            <template v-for="itemResource in resources" :key="itemResource.id">
              <div v-if="!!itemResource.media && itemResource.media.length > 0" class="text-sm mb-16">
                <div class="fw-medium">{{ itemResource.resource.name }}</div>

                <ul class="list-group-with-dot list-group">
                  <li
                    v-for="file in itemResource.media"
                    :key="file.id"
                    class="list-group-item d-flex align-items-center"
                  >
                    <v-icon class="me-8" icon="show-details" />

                    <a
                      :aria-label="file.name"
                      :href="file.storageUrl"
                      class="fw-medium btn-link file-name overflow-hidden d-inline-block
                        w-100 white-space-nowrap text-overflow-ellipsis text-sm p-0"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {{ file.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <the-travel-book-location-resources-modal
    v-if="modalToShow === 'the-travel-book-location-resources-modal'"
    :location="location"
    :location-id="locationId"
    @closed="closeModal"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import { mapState } from "pinia";
import VIcon from "@/components/vendor/basic/icon/VIcon.vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import VPicture from "@/components/vendor/basic/picture/VPicture.vue";
import Supplier from "@/api/objects/Supplier";
import TourDay from "@/api/objects/TourDay";
import useModalUtils from "@/helpers/ModalUtils";
import VButton from "@/components/vendor/basic/button/VButton.vue";
import TheTravelBookLocationResourcesModal from "@/components/tour/book/parts/TheTravelBookLocationResourcesModal.vue";
import TourDayItem from "@/api/objects/TourDayItem";
import TourDayItemResource from "@/api/objects/TourDayItemResource";
import { useAppStore } from "@/stores/app";

export default defineComponent({
  name: "VTravelBookInformation",
  components: {
    TheTravelBookLocationResourcesModal,
    VIcon,
    VPicture,
    Swiper,
    SwiperSlide,
    VButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    tourDay: {
      type: TourDay,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    location: {
      type: String,
      required: false,
      default: "",
    },
    origin: {
      type: String,
      required: false,
      default: "",
    },
    destination: {
      type: String,
      required: false,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    isStage: {
      type: Boolean,
      required: false,
      default: true,
    },
    titleIcon: {
      type: String,
      required: false,
      default: "info",
    },
    showDataItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    supplierName: {
      type: String,
      required: false,
      default: "",
    },
    mapUrl: {
      type: String,
      required: false,
      default: "",
    },
    siteUrl: {
      type: String,
      required: false,
      default: "",
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
    resources: {
      type: Array,
      required: false,
    },
    images: {
      type: Array,
      required: false,
    },
    isLocationInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    locationId: {
      type: Number,
      required: false,
    },
    dayItem: {
      type: TourDayItem,
      required: false,
    },
    parseImages: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ...useModalUtils(),
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      supplier: {} as Supplier,
      imagesAux: [] as any,
      isLargeTextCollapsed: true,
      currentLanguage: localStorage.getItem("language"),
    };
  },
  computed: {
    ...mapState(useAppStore, ["settings"]),
    viewMoreIsActive(): boolean {
      return this.$route.query.viewMore === undefined || this.$route.query.viewMore === "1";
    },
    messageLenght() {
      const div = document.createElement("div");
      div.innerHTML = this.message;

      return div.textContent?.length || div.innerText.length || "".length;
    },
    showFiles(): boolean {
      let show = false;

      if (!!this.resources && this.resources.length > 0) {
        this.resources.forEach((resource) => {
          if (!!resource && !!resource.media && resource.media.length > 0) {
            show = true;
          }
        });
      }
      return show;
    },
  },
  created() {
    if (this.parseImages) {
      this.imagesAux = this.getSupplierAndResourcesImages();
    } else {
      this.imagesAux = this.images;
    }
  },
  methods: {
    toggleCollapsedText() {
      this.isLargeTextCollapsed = !this.isLargeTextCollapsed;
    },
    getSupplierAndResourcesImages(): any[] {
      let media: any[] = [];

      if (!!this.dayItem && !!this.dayItem.resources && this.dayItem.resources.length > 0) {
        if (!!this.dayItem.resources[0].resource) {
          const { supplier } = this.dayItem.resources[0].resource;

          this.supplier = supplier as Supplier;

          if (!!supplier?.media && supplier?.media?.length > 0) {
            media = supplier?.media;
          }
        }

        const dayItemResourcesUniq = [
          ...new Map(
            this.dayItem.resources.map((itemResource: TourDayItemResource) => [itemResource.resource.id, itemResource])
          ).values(),
        ];

        dayItemResourcesUniq.forEach((itemResource: TourDayItemResource) => {
          if (!!itemResource.resource && !!itemResource.resource.media && itemResource.resource.media.length > 0) {
            media = media.concat(itemResource.resource.media);
          }
        });
      }

      return media;
    },
  },
});
</script>

<style lang="scss" scoped>
.break-avoid {
  break-inside: avoid;
}

.hide-print {
  @media print {
    display: none !important;
  }
}

.only-print {
  @media only screen {
    display: none !important;
  }
}

.message {
  @media print {
    font-size: 0.875rem;
  }

  .collapsable-text.collapsing {
    height: 300px;
  }

  .collapsable-text {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4rem;
      opacity: 0;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
      transition: opacity 0.1s ease-out;
    }

    &.collapsing {
      &:after {
        opacity: 1;
      }
    }

    &.collapse:not(.show) {
      display: block !important;
      height: 300px;
      max-height: 300px;
      overflow: hidden;
      position: relative;

      &:after {
        opacity: 1;
      }
    }
  }

  .btn {
    width: fit-content;
  }
}

.accordion-button {
  @media print {
    &:after {
      display: none;
    }
  }
}

.information-container {
  border-radius: 0.5rem;
  box-shadow: 0 1px 0.25rem var(--uq-gray-300);
  background-color: var(--uq-white);

  @media print {
    box-shadow: none !important;
  }

  .left {
    .image-container {
      :deep(img) {
        border-radius: 0.5rem 0.5rem 0 0;

        @media (min-width: 992px) {
          border-top-right-radius: 0;
          border-bottom-left-radius: 0.5rem;
        }
      }
    }
  }

  .right {
    .image-container {
      :deep(img) {
        border-radius: 0 0 0.5rem 0.5rem;

        @media (min-width: 992px) {
          border-top-right-radius: 0.5rem;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    :deep(img) {
      height: 20rem;

      @media (min-width: 992px) {
        height: 30rem;
      }
    }
  }

  .data-container-info {
    .contact-container,
    .file-container,
    .address-container {
      position: relative;
      padding-top: 1rem;
      margin-top: 1rem;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: var(--uq-gray-400);
      }
    }

    .contact-data {
      display: grid;
      grid-template-columns: 10% 80%;

      .contact-data-title {
        font-size: 0.875rem;
      }

      .contact-data-value {
        font-size: 0.875rem;
      }
    }
  }
}
</style>

<style lang="scss">
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1.125rem;
  background: var(--uq-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
  width: 2.5rem;
  padding: 0.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--uq-white);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: var(--uq-primary);
  font-weight: 600;
  font-size: 1.125rem;
}

.swiper-pagination-fraction {
  font-size: 0.875rem;
  padding-inline: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(var(--uq-white-rgb), 0.85);
  width: fit-content;
  left: 50%;
  transform: translate(-50%);
}

.image-container img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
