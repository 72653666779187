<template>
  <v-modal
    id="supplier-details-rates-create-modal"
    size="lg"
    @closed="$emit('closed')"
  >
    <modal-header>
      <modal-title>
        <template v-if="!!priceData">
          {{ $t('supplier.rate.update') }}
        </template>

        <template v-else>
          {{ $t('supplier.rate.create') }}
        </template>
      </modal-title>
    </modal-header>

    <modal-body>
      <form
        id="supplier-resource-rate-create-form"
        class="d-flex flex-column justify-content-between h-100"
      >
        <fieldset class="mb-16">
          <legend class="visually-hidden">{{ $t('supplier.rate.basicInformation') }}</legend>

          <div class="row gx-16">
            <div class="col-12 col-lg-6">
              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.type') }}*</span>
                </label>

                <v-select
                  id="supplier-resource-rate-create-type"
                  v-model="type"
                  :class="{ 'is-invalid': !!errors.type }"
                  :options="priceTypes"
                  :reduce="returnValue => returnValue.key"
                >
                  <template v-slot:no-options>
                    {{ $t('supplier.rate.typeNoOptions') }}
                  </template>
                </v-select>

                <span v-if="!!errors.type" class="invalid-feedback d-block">
                  {{ errors.type }}
                </span>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.category') }}*</span>
                </label>

                <v-select
                  id="supplier-resource-rate-create-category"
                  v-model="category"
                  :class="{ 'is-invalid': !!errors.category }"
                  :options="priceCategories"
                  :reduce="returnValue => returnValue.key"
                >
                  <template v-slot:no-options>
                    {{ $t('supplier.rate.categoryNoOptions') }}
                  </template>
                </v-select>

                <span v-if="!!errors.category" class="invalid-feedback d-block">
                  {{ errors.category }}
                </span>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div id="supplier-resource-rate-create-init-date" class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.initDate') }}*</span>
                </label>

                <Datepicker
                  v-model="initDate"
                  :enable-time-picker="false"
                  :format="settings.formatDate"
                  :input-class-name="`form-control ${!!errors.initDate ? 'is-invalid' : ''}`"
                  :max-date="!!endDate ? endDate : null"
                  :month-change-on-scroll="false"
                  :start-date="!!endDate ? endDate : null"
                  auto-apply
                  class="form-datepicker"
                />

                <span v-if="!!errors.initDate" class="invalid-feedback d-block">
                  {{ errors.initDate }}
                </span>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div id="supplier-resource-rate-create-end-date" class="form-outline">
                <label class="label">
                  <span>{{ $t('general.shared.endDate') }}</span>
                </label>

                <Datepicker
                  v-model="endDate"
                  :enable-time-picker="false"
                  :format="settings.formatDate"
                  :input-class-name="`form-control ${!!errors.endDate ? 'is-invalid' : ''}`"
                  :min-date="!!initDate ? initDate : null"
                  :month-change-on-scroll="false"
                  :start-date="!!initDate ? initDate : null"
                  auto-apply
                  class="form-datepicker"
                />

                <span v-if="!!errors.endDate" class="invalid-feedback d-block">
                  {{ errors.endDate }}
                </span>
              </div>
            </div>

            <div class="col-12">
              <v-form-input
                id="supplier-resource-rate-create-price"
                v-model="price"
                :label="`${$t('general.shared.price')}*`"
                :yup-errors-variable="errors.price"
                autocomplete="off"
                form-type="outline"
                onwheel="return false;"
                type="number"
              />
            </div>
          </div>
        </fieldset>

        <v-button
          :disabled="!meta.valid || accepted"
          :is-loading="accepted"
          class="btn-submit-form btn-icon w-fit align-self-end"
          size="sm"
          variant="primary"
          @click="createSupplierResourcePrice"
        >
          <template v-if="!!priceData">{{ $t('general.button.save') }}</template>
          <template v-else>{{ $t('general.button.create') }}</template>
          <v-icon icon="arrow-right" size="sm" space="ms-12"/>
        </v-button>
      </form>
    </modal-body>
  </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import vSelect from 'vue-select';
import Datepicker from '@vuepic/vue-datepicker';
import { VFormInput } from '@uniqoders/form';
import { mapState } from 'pinia';
import VModal from '@/components/vendor/basic/modal/VModal.vue';
import ModalHeader from '@/components/vendor/basic/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/basic/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/basic/modal/ModalBody.vue';
import useConcepts from '@/helpers/Concepts';
import useModalUtils from '@/helpers/ModalUtils';
import SupplierResourcePrice from '@/api/objects/SupplierResourcePrice';
import { mapConcepts } from '@/helpers/ConceptHelper';
import useFormValidation from '@/helpers/form';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import VIcon from '@/components/vendor/basic/icon/VIcon.vue';
import { useAppStore } from '@/stores/app';

export default defineComponent({
  name: 'TheSupplierDetailsRatesCreateModal',
  components: {
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    vSelect,
    Datepicker,
    VButton,
    VFormInput,
    VIcon,
  },
  emits: [
    'closed',
    'emitReloadSupplierRates',
  ],
  props: {
    resource: {
      type: Number,
      required: true,
    },
    priceData: {
      type: SupplierResourcePrice,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const rules = yup.object({
      category: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.category')),
      type: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.type')),
      initDate: yup.string()
        .required()
        .nullable()
        .label(t('general.shared.initDate')),
      endDate: yup.string()
        .nullable()
        .label(t('general.shared.endDate')),
      price: yup.number()
        .required()
        // eslint-disable-next-line no-restricted-globals
        .transform((value) => (isNaN(value) ? undefined : value))
        .label(t('general.shared.price')),
    });

    const initialValues: Record<string, any> = {};

    if (!!props.priceData) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      initialValues.category = props.priceData.category;
      // eslint-disable-next-line vue/no-setup-props-destructure
      initialValues.type = props.priceData.type;
      // eslint-disable-next-line vue/no-setup-props-destructure
      initialValues.initDate = props.priceData.initDate;
      // eslint-disable-next-line vue/no-setup-props-destructure
      initialValues.endDate = props.priceData.endDate;
      // eslint-disable-next-line vue/no-setup-props-destructure
      initialValues.price = props.priceData.price;
    }

    const form = useFormValidation(rules, initialValues);

    const { value: category } = useField<number>('category');
    const { value: type } = useField<number>('type');
    const { value: initDate } = useField<number>('initDate');
    const { value: endDate } = useField<number>('endDate');
    const { value: price } = useField<number>('price');

    return {
      ...useConcepts(),
      ...useModalUtils(),
      ...form,
      category,
      type,
      initDate,
      endDate,
      price,
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useAppStore, ['settings']),
    priceTypes(): any {
      if (!!this.concepts && this.concepts['supplier_resource_price.type']) {
        return mapConcepts(this.concepts['supplier_resource_price.type']);
      }

      return [];
    },
    priceCategories(): any {
      if (!!this.concepts && this.concepts['supplier_resource_price.category']) {
        return mapConcepts(this.concepts['supplier_resource_price.category']);
      }

      return [];
    },
  },
  methods: {
    async createSupplierResourcePrice() {
      try {
        const data = {
          category: this.category,
          type: this.type,
          init_date: this.initDate,
          end_date: this.endDate,
          price: this.price,
        };

        if (!!this.priceData) {
          await api.supplierResourcePrice.update(this.resource, this.priceData.id, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));
        } else {
          await api.supplierResourcePrice.create(this.resource, data);

          this.$toast.success(this.$t('general.shared.savedChanges'));

          this.cleanForm();
        }

        this.$emit('emitReloadSupplierRates');
      } catch (e: any) {
        console.error(e);
      }
    },
    cleanForm() {
      this.resetForm();
    },
  },
});
</script>
